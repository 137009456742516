import React from 'react';
import { Grid, Typography, Button } from '@mui/material';
import ExerciseCard from './ExerciseCard';
import ExerciseThumbnail from './ExerciseThumbnail';
import { useNavigate } from 'react-router-dom';

interface WorkoutDetailsProps {
  workoutPlan: any;
  exercisesData: any[];
}

const WorkoutDetails: React.FC<WorkoutDetailsProps> = ({ workoutPlan, exercisesData }) => {
  const navigate = useNavigate();

  const getExercisesForWorkout = (exerciseIds: number[]) => {
    // Maintain the order of exerciseIds
    return exerciseIds.map(id => exercisesData.find(exercise => exercise.id === id)).filter(Boolean);
  };

  const handleTrackProgress = () => {
    navigate(`/track-progress/${workoutPlan.id}`);
  };

  return (
    <div>
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={12} md={8}>
          <Typography variant="h2">{workoutPlan.title}</Typography>
        </Grid>
        <Grid item xs={12} md={4} style={{ textAlign: 'right' }}>
          <Button variant="contained" onClick={handleTrackProgress}>
            Track Your Progress
          </Button>
        </Grid>
      </Grid>
      <Typography>{workoutPlan.description}</Typography>
      <Grid container spacing={2}>
        {getExercisesForWorkout(workoutPlan.exercises).map((exercise) => (
          <Grid item xs={6} sm={4} md={2} key={exercise!.id}>
            {/* <ExerciseCard exercise={exercise} /> */}
            <ExerciseThumbnail exercise={exercise!} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default WorkoutDetails;
