import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import BlackLogo from '../assets/logo/Black_Logo.png';
import DisplayPic from "../assets/images/Example_DP.jpg";
import { Navigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { styled } from '@mui/system';
import LogoFullPlayFair from '../assets/logo/Logo_Full_Playfair.svg';
import HeaderDesktop from './HeaderDesktop';
import HeaderMobile from './HeaderMobile';
import CookieIcon from '@mui/icons-material/Cookie';  // Add this line

const AppBarStyled = styled(AppBar)`
  border-bottom: 1px solid black;
`;

const LogoBox = styled(Box)`
    display: flex;
    align-items: center;
`;

export const NavButton = styled(Button)`
    text-transform: none;
    margin-top: 16px;
    margin-bottom: 16px;
    color: 'black';
    display: 'block';
    margin-left: 16px;

    &.active {
      background-color: #cccccc; // this is the color for the active state
  }
`;

const pages = [
  { label: 'Home', url: "/" },
  { label: 'Workout Plans', url: "/workouts" },
  { label: 'My Progress', url: "/my-progress" },
  { label: 'Find A PT', url: "/find-a-pt" },
  { label: 'All Exercises', url: "/exercises" },
  // { label: 'Nutrition Guidance', url: "/nutrition" },
  // { label: 'Progress Tracking', url: "/progress" },
  // { label: 'Community Support', url: "/community" }
];

const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

function TopBanner() {

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const currentDate = moment().format('Do MMMM, h:mma'); // Format current date and time

  return (
    <AppBarStyled position="sticky" color="default">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Link to="/">
            <LogoBox mr={1}>
              <img src={LogoFullPlayFair} alt='Gym Progress Logo' width="100px" />
            </LogoBox>
          </Link>
          <HeaderMobile pages={pages} handleOpenNavMenu={handleOpenNavMenu} handleCloseNavMenu={handleCloseNavMenu} anchorElNav={anchorElNav} />
          <HeaderDesktop pages={pages} handleCloseNavMenu={handleCloseNavMenu} />
          <Box sx={{ flexGrow: 0, display: 'flex', alignItems: 'center' }}>
            {currentDate}
            <Tooltip title="View Cookies">
              <IconButton component={Link} to="/cookies" color="inherit">
                <CookieIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Toolbar>
      </Container>
    </AppBarStyled>
  );
}
export default TopBanner;
