import React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/system';
import { Typography } from '@mui/material';
import { Exercise } from './WorkoutsList'; // Adjust the import path
import staticImage from '../assets/images/exercises/pull_ups.jpg';

const defaultImagePath = require('../assets/images/exercises/no_image_available.jpg'); // Full path to your default image

const ThumbnailBox = styled(Box)`
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 6px;
  background-color: #f5f5f5;
  text-align: center;
`;

const ThumbnailImg = styled("img")`
  width: 100%;
  height: auto;
  border-radius: 6px;
  object-fit: cover;
`;

interface ExerciseThumbnailProps {
  exercise: Exercise;
}

const ExerciseThumbnail: React.FC<ExerciseThumbnailProps> = ({ exercise }) => {
  // Dynamic Image Import (with fallback)
  let imagePath = null;
  try {
    imagePath = require(`../assets/images/exercises/${exercise.image}`);
  } catch (error) {
    console.error('Error loading image:', error); // Log the error
    imagePath = defaultImagePath;
  }

  return (
    <ThumbnailBox>
      <ThumbnailImg src={imagePath} alt={exercise.title} />
      <Typography variant='h5' component="h3" mt={2}>{exercise.title}</Typography>
    </ThumbnailBox>
  );
};

export default ExerciseThumbnail;
