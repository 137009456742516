import React from 'react';
import WorkoutDetails from './WorkoutDetails';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';


const WorkoutBox = styled(Box)`
  margin-bottom: 20px;
  padding-top: 20px;
  border-top: 2px solid grey;
  // border-bottom: 2px solid grey;
`;

interface Exercise {
  id: number;
  title: string;
  description: string;
  videoUrl: string;
  image: string;
  isGymOnly: boolean;
  isHomeOnly: boolean;
  isBuildingMuscle: boolean;
  isCardio: boolean;
  isBeginnerSafe: boolean;
  isIntermediatePro: boolean;
  musclesUsed: string[];
}

interface WorkoutPlan {
  id: number;
  title: string;
  description: string;
  rating: number;
  exercises: number[];
}

interface WorkoutPlansProps {
  workoutPlansData: WorkoutPlan[];
  exercisesData: Exercise[];
}

const WorkoutPlans: React.FC<WorkoutPlansProps> = ({ workoutPlansData, exercisesData }) => {
  return (
    <div>
      {workoutPlansData.map((workoutPlan) => (
        <WorkoutBox key={workoutPlan.id}>
          {/* <Typography variant="h2">{workoutPlan.title}</Typography>
          <Typography>{workoutPlan.description}</Typography> */}
          <WorkoutDetails workoutPlan={workoutPlan} exercisesData={exercisesData} />
        </WorkoutBox>
      ))}
    </div>
  );
};

export default WorkoutPlans;
