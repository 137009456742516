import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Box, Typography, Button, Card, CardContent, CardMedia, Tab, Tabs, Skeleton, Grid } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import trainersData from '../pts.json';
import PageContainer from '../components/PageContainer';

const PTDetailPage: React.FC = () => {
  const { trainerId } = useParams<{ trainerId: string }>();
  const trainer = trainersData.find(t => t.id.toString() === trainerId);

  const [activeTab, setActiveTab] = useState(0);

  if (!trainer) {
    return <Typography variant="h4">Trainer not found</Typography>;
  }

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const renderSkeletons = (count: number) => {
    return Array.from({ length: count }).map((_, index) => (
      <Grid item xs={6} md={4} lg={3} key={index}>
        <Skeleton variant="rectangular" width="100%" height={150} />
      </Grid>
    ));
  };

  return (
    <PageContainer>
      <Box display="flex" justifyContent="space-between">
        {/* Left Column - PT Image and Details */}
        <Box width="35%">
          <Card>
            <CardMedia
              component="img"
              image={require(`../assets/images/pts/${trainer.image}`)}
              alt={trainer.name}
              style={{ height: 350, objectFit: 'cover' }}
            />
            <CardContent>
              <Typography variant="h4">{trainer.name}</Typography>
              <Typography variant="body1" color="textSecondary">{trainer.qualification}</Typography>
              <Typography variant="body2" color="textSecondary">{trainer.experience_level}</Typography>
              <Typography variant="body2" color="textSecondary" mb={2}>{trainer.expertise}</Typography>
              <Typography variant="body1" mt={2}>{trainer.description}</Typography>
              <Button variant="outlined" color="primary" style={{ marginTop: '1rem', width: '100%' }}>
                Tip this Trainer
              </Button>
            </CardContent>
          </Card>
        </Box>

        {/* Right Column - Subscription Tiers */}
        <Box width="60%">
          <Tabs value={activeTab} onChange={handleTabChange} variant="fullWidth" centered>
            <Tab label="Free Tier" />
            <Tab label={
              <Box display="flex" alignItems="center">
                {activeTab === 1 ? "" : <LockIcon fontSize="small" style={{ marginRight: 4 }} />}
                Economy Tier
              </Box>
            } />
            <Tab label={
              <Box display="flex" alignItems="center">
                {activeTab === 2 ? "" : <LockIcon fontSize="small" style={{ marginRight: 4 }} />}
                Premium Tier
              </Box>
            } />
          </Tabs>

          <Box mt={2} p={2} border="1px solid #ccc" borderRadius={8}>
            {activeTab === 0 && (
              <Box>
                <Grid container>
                    <Grid xs={6}>
                        <Typography variant="h6">Free Tier</Typography>
                        <Typography variant="body2" mb={2}>Get a taste of the trainer's content for free.</Typography>
                    </Grid>
                    <Grid xs={6} style={{ textAlign: 'end' }}>
                        <Button variant="contained" color="primary" style={{ marginTop: '1rem'}}>
                            Access Free Content
                        </Button>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                {renderSkeletons(12)}
                </Grid>
                
              </Box>
            )}
            {activeTab === 1 && (
              <Box>
                <Grid container>
                    <Grid xs={6}>
                        <Typography variant="h6">Economy Tier - £5/month</Typography>
                        <Typography variant="body2" mb={2}>Unlock additional content with this affordable plan.</Typography>
                    </Grid>
                    <Grid xs={6} style={{ textAlign: 'end' }}>
                        <Button variant="contained" color="primary" style={{ marginTop: '1rem'}}>
                            Subscribe to Economy
                        </Button>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                {renderSkeletons(12)}
                </Grid>
              </Box>
            )}
            {activeTab === 2 && (
              <Box>
                 <Grid container>
                    <Grid xs={6}>
                        <Typography variant="h6">Premium Tier - £10/month</Typography>
                        <Typography variant="body2" mb={2}>Get access to all exclusive content, including personalized advice.</Typography>
                    </Grid>
                    <Grid xs={6} style={{ textAlign: 'end' }}>
                        <Button variant="contained" color="primary" style={{ marginTop: '1rem'}}>
                            Subscribe to Premium
                        </Button>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    {renderSkeletons(12)}
                </Grid>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </PageContainer>
  );
};

export default PTDetailPage;
